.leaflet-top.leaflet-left,
.leaflet-bottom.leaflet-right {
  display: none;
}

.leaflet-div-icon {
  border: none;
  background: none;
}

.leaflet-popup {
  bottom: 0 !important;
}

.leaflet-popup-content-wrapper {
  background: #262936;
  /* border-radius: 0.28571429rem; */
}

.leaflet-popup-tip-container .leaflet-popup-tip {
  background: #262936;
}
.leaflet-container {
  border-radius: 0.28571429rem;
  background: #262936;
}
