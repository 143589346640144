* {
  font-family: "Lato", sans-serif;
}

html {
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  background: #363c4f;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 767px) {
  .ui.grid .ui.stackable.grid,
  .ui.segment:not(.vertical) .ui.stackable.page.grid.portrait {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #02adc3 !important;
  font-size: 24px;
}

[class^="slick"] {
  height: 100%;
}

.rc-slider-track {
  background-color: #136b7c !important;
  height: 10px !important;
}
.rc-slider-rail {
  background-color: #8c94b4 !important;
  height: 10px !important;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}
.rc-slider-handle {
  background-color: #02adc3 !important;
  box-shadow: none !important;
  border: none !important;
  height: 19px !important;
  width: 19px !important;
}

.rc-slider-handle:active {
  box-shadow: none !important;
}

.rc-slider-tooltip-inner {
  background-color: #161820 !important;
  box-shadow: none !important;
}

.rc-slider-tooltip-arrow {
  border-top-color: #161820 !important;
}

.ui.floating.dropdown .menu {
  left: -17rem;
  background: rgb(38, 41, 54);
}

.ui.floating.dropdown .menu .item {
  color: rgb(102, 109, 137);
}

.ui.page.modals.dimmer.transition.visible.active {
  display: flex !important;
}

.ui.small.modal.transition.visible.active {
  background: none;
  margin-top: auto !important;
}

.ui.active.modal {
  margin-top: initial !important;
}

.add-farmer {
  margin: auto !important;
  margin-top: auto !important;
}

.example-enter {
  opacity: 0.01;
  transform: translateY(-10%);
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: 0.25s ease-in-out;
  transform: translateY(0%);
}

.example-leave {
  opacity: 1;
  transform: translateY(0%);
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: 0.25s ease-in-out;
  transform: translateY(-10%);
}

.loader-enter {
  opacity: 0.01;
}

.loader-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.loader-leave {
  opacity: 1;
}

.loader-leave.loader-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.Loading__bar___21yOt {
  height: 3px;
}
